@import "./normalize.scss";
@import "./variable.scss";
@import "./header.scss";
@import "./carousel.scss";
@import "./main.scss";
@import "./footer.scss";
@import "./contactform.scss";
@import "./gallery.scss";
@import "./about.scss";
@import "./contact.scss";

html {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    color: $font-color;
    font-family: "Source Sans Pro", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-family: $main-font;
    font-size: 100%;
}
main {
    animation: fadein 1s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.group:before,
.group:after {
    content: " ";
    display: table;
}

.group:after {
    clear: both;
}

.group {
    *zoom: 1;
}

a {
    color: $font-color;
    text-decoration: none;
    display: inline-block;
}

.container {
    max-width: 1150px;
    margin: 0 10%;
}
img {
    max-width: 100%;
    height: auto;
}
.btn {
    padding: 0.6em 1.2em;
    background-color: #f06800;
    opacity: 1;
    border-radius: 0.3em;
    font-size: 0.9em;
    transition: 0.3s ease-in-out;
}
.products .btn {
    margin-bottom: 1em;
}
.btn:hover,
.btn:focus,
button:hover {
    transform: scale(1.05);

    background-color: $secondary-color;
}

section {
    padding: 1em 0;
    border-bottom: 1px solid $secondary-color;
}
.gdpr {
    text-align: left;
    line-height: 2;
    ul {
        font-size: 0.8em;
    }
}

@media screen and (min-width: 65em) {
    body {
        font-size: 115%;
    }
    .secondary-menu {
        font-size: 100%;
    }
}
.error-pic {
    max-width: 30%;
    margin-bottom: 1em;
    border-radius: 3%;
}
.error-btn {
    display: block;
}
@media screen and (max-width: 20em) {
    body {
        font-size: 70%;
    }
}
@media (min-width: 1400px) {
    .container {
        margin: 0 auto;
    }
}
