.carousel {
    font-size: 0.85em;
    padding: 0;
    border-bottom: none;
    position: relative;
    overflow: hidden;
    line-height: 0;
    max-height: 82vh;
    p {
        font-size: 1.1em;

        margin: 1em;
    }

    img {
        width: 100%;
        animation: 100s zooming infinite;

        @keyframes zooming {
            0% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.3);
            }
            100% {
                transform: scale(1);
            }
        }
    }

    .container {
        line-height: 1.15;
        font-size: 77%;
        color: $font-color2;
        background-color: rgba($font-color, $alpha: 0.75);
        position: absolute;
        top: 16%;
        left: 1em;
        right: 1em;

        padding-bottom: 1.5em;
        text-align: center;
        border-radius: 0.3em;
    }
    h1 {
        font-size: 1.7em;
    }
}

@media screen and (min-width: 20em) {
    .carousel {
        .container {
            top: 24%;
        }
    }
}
@media screen and (min-width: 34em) {
    .carousel {
        font-size: 1em;
        .container {
            top: 24%;
            font-size: 100%;
        }
    }
}

@media screen and (min-width: 50em) {
    .carousel {
        font-size: 1.2em;
        .container {
            top: 27%;
        }
    }
}
