.products,
.contacts,
.gdpr {
    color: $font-color2;
    text-align: center;
    font-size: 1.5em;
    padding-top: 0;
    h1 {
        font-size: 1.6em;
        margin: 0.8em;
    }

    .container {
        margin-bottom: 3em;
    }
    .gallery {
        a {
            display: inline-block;
            overflow: hidden;
            font-size: 0;
        }
        column-count: 2;
        column-gap: 0.3em;

        img {
            width: 100%;
            height: auto;
        }
        img:hover {
            transition: 0.3s ease-in-out;
            transform: scale(1.05);
        }
    }
}

#overlay {
    background-color: rgba($color: #000000, $alpha: 0.7);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    text-align: center;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#overlay img {
    border: 5px solid white;
    max-height: 85%;
    max-width: 80%;
    width: auto;
    border-radius: 5px;
}

.animate {
    animation: view 1s ease-in-out;
    @keyframes view {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
.animate-two {
    animation: view-two 1s ease-in-out;
    @keyframes view-two {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
.controls {
    font-size: 1em;
    width: 100%;
    height: 100%;
    position: absolute;
    color: white;
}

.fa-times {
    position: absolute;
    top: 2%;
    right: 2%;
}
.fa-arrow-left {
    position: absolute;
    top: 50%;
    left: 2%;
}
.fa-arrow-right {
    position: absolute;
    top: 50%;
    right: 2%;
}

.fa-arrow-left:hover,
.fa-arrow-right:hover {
    transition: 0.3s ease-in-out;
    transform: scale(1.2);
    cursor: pointer;
}
.fa-times:hover {
    transition: 0.5s ease-in-out;
    transform: rotate(90deg);
    cursor: pointer;
}
@media screen and (min-width: 45em) {
    .controls {
        font-size: 2em;
    }
    .products {
        .gallery {
            margin-top: 2em;
            column-count: 4;
        }
    }
}
