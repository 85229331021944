#map {
    height: 400px;
    width: 100%;
}
.contact-list {
    list-style: none;
    padding-left: 0;
    a {
        color: $font-color2;
    }
    i {
        color: $main-color;
        padding-right: 0.5em;
    }

    li {
        text-align: center;
        padding-bottom: 1em;
    }
    li:first-child {
        font-weight: bold;
    }
}
@media screen and (min-width: 45em) {
    .contacts {
        .flex {
            justify-content: space-between;
            flex-direction: row-reverse;
        }
    }
}
