.about-subheader {
    margin-bottom: 1.5em;
}
.about-list {
    list-style: none;
    text-align: center;
    position: relative;
    i {
        font-size: 1em;
        margin-right: 1em;
        color: $main-color;
    }
    li {
        position: relative;
        left: -1.2em;
        margin-bottom: 1.2em;
    }
}
.about-img {
    margin-top: 2em;
}

@media screen and (min-width: 42em) {
    .about-list {
        display: flex;
        justify-content: space-around;
        text-align: left;
        flex-wrap: nowrap;
    }
}


@media screen and (min-width: 34em) {
    li {
        font-size: 1.2em;
    }
}
