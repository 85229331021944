.contact-bar {
    display: flex;
    justify-content: space-evenly;
    color: $main-color;
    background-color: white;
    p {
        margin: 0.6em;
    }
    a {
        color: $font-color2;
        padding-left: 0.5em;
    }
}
.mail:hover,
.phone:hover {
    transition: 0.3s ease-in-out;
    transform: scale(1.05);
}
/* header */

.header {
    position: sticky;
    top: 0;
    z-index: 999;

    background-color: $secondary-color;
}

.header h1 {
    margin: 0;
}

.header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: $secondary-color;
    text-transform: uppercase;
    font-size: 1em;
    text-align: center;
}

.header li a {
    display: inline-block;
    padding: 20px 0;

    text-decoration: none;
    position: relative;
}

.header li a::after {
    display: inline-block;
    content: "";
    height: 1px;
    width: 0;
    background-color: whitesmoke;
    transition: 0.3s ease-in-out;
    position: absolute;
    top: 72%;
    left: 0%;
}
.header li a:hover::after,
.header li a:focus::after .header li a:active::after {
    width: 100%;
}

.header .logo {
    display: block;
    float: left;
    padding: 0.2em 0 0 0.8em;
    text-decoration: none;
}
.logo img {
    max-width: 8em;
    margin: 0 0 0 0.5em;
}

/* menu */

.header .menu {
    clear: both;
    max-height: 0;
    transition: max-height 0.4s ease-out;
}

/* menu icon */

.header .menu-icon {
    cursor: pointer;
    display: block;
    float: right;
    padding: 1.8em 2em;
    position: relative;
    user-select: none;
}

.header .menu-icon .navicon {
    background: $font-color;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.4s ease-out;
    width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
    background: $font-color;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.4s ease-out;
    width: 100%;
}

.header .menu-icon .navicon:before {
    top: 0.4em;
}

.header .menu-icon .navicon:after {
    top: -0.4em;
}

/* menu btn */

.header .menu-btn {
    display: none;
}

.header .menu-btn:checked ~ .menu {
    max-height: 440px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
}

@media (min-width: 55em) {
    .header li {
        float: left;
        font-size: 1em;
    }
    .header li a {
        padding: 1em 1.6em;
        border-right: 1px solid #f4f4f4;
    }
    .header .menu {
        clear: none;
        float: right;
        max-height: none;
    }
    .header .menu-icon {
        display: none;
    }
    .header ul {
        margin-top: 0.5em;
    }
    .header li a::after {
        top: 71%;
        left: 11%;
    }
    .header li a:hover::after {
        width: 80%;
    }
}
@media screen and (min-width: 45em) {
    .phone::before {
        content: "Telefon: ";
    }

    .mail::before {
        content: "Email: ";
    }
}
